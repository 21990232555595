<template>
	<div>
		<el-dialog :draggable="true" width="60%" top="5vh" v-model="outerVisible" @close="Reset" title="详细资料">
			<div class="dialog no-bgs">
				<el-form ref="ruleForm" label-width="150px">
					<el-form-item label="不通过的原因" v-if="ruleForm.status==2">
						<span class="xx"></span>
						<el-input type="textarea" disabled clearable v-model="ruleForm.rejection_reason" placeholder="">
						</el-input>
					</el-form-item>

					<el-form-item label="会员列表" v-if="ruleForm.status==1">
						<span class="xx"></span>
						<el-button @click="$router.push({path:'/uselist',query:{id:ruleForm.id}})"
							size="mini">查看详细</el-button>
					</el-form-item>
					<el-form-item label="开会时间" v-if="ruleForm.status==1">
						<span class="xx"></span>
						<el-input disabled clearable v-model="meet_time" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="公司名称">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.company_name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="公司职工人数">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.number" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="公司性质">
						<span class="xx"></span>
						<el-select disabled clearable v-model="ruleForm.nature_type" placeholder="">
							<el-option label="私营企业" :value="1"></el-option>
							<el-option label="国有企业" :value="2"></el-option>
							<el-option label="公司性质" :value="3"></el-option>
							<el-option label="机关事业单位" :value="4"></el-option>
							<el-option label="社会团体" :value="5"></el-option>
							<el-option label="其他" :value="5"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="公司营业执照图片">
						<ul class="imglist">
							<li v-for="(item,index) in srcList" :key="index">
								<el-image style="width: 100%; height: 100%" :preview-src-list="srcList" :src="item" />
							</li>
						</ul>
					</el-form-item>
					<el-form-item label="公司营业执照Word/PDF">
						<div class="fu-list">
							<div class="fu-li" v-for="(item,index) in annex" :key="index">
								{{item.OriginalName}} <el-button @click="download(item.url)" color="#488bff"
									class="el-white btn" style="color: white" size="mini">下载
								</el-button>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="姓名">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="职务">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.position" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="身份证号码">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.id_card" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="手机号码">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleForm.phone" placeholder=""></el-input>
					</el-form-item>
					<div class="title" v-if="ruleForm.status==1">
						<div>
							工会委员
						</div>
					</div>
					<el-form-item label="工会主席" v-if="ruleForm.status==1">
						<span class="xx"></span>
						<el-input disabled clearable v-model="president" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="工会副主席" v-if="ruleForm.status==1">
						<span class="xx"></span>
						<el-input disabled clearable v-model="vice_president" placeholder=""></el-input>
					</el-form-item>
					<div class="title" v-if="ruleForm.status==1">
						<div class="f">
							会议选举结果报告

						</div>
						<el-tag v-if="ruleFormxj.status==0">审核中</el-tag>
						<el-tag type="success" v-if="ruleFormxj.status==1">已通过</el-tag>
						<el-tag v-if="ruleFormxj.status==2" type="danger">不通过</el-tag>
					</div>
					<el-form-item label="选举时间" v-if="ruleForm.status==1">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleFormxj.time" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="工会委员会" v-if="ruleForm.status==1">
						<span class="xx"></span>
						<el-button @click="binmember" size="mini">查看详细</el-button>
					</el-form-item>
					<el-form-item label="经费审查委员会" v-if="ruleForm.status==1">
						<span class="xx"></span>
						<el-button @click="binfunds" size="mini">查看详细</el-button>
					</el-form-item>
					<el-form-item label="女职工委员会" v-if="ruleForm.status==1">
						<span class="xx"></span>
						<el-button @click="binworkers" size="mini">查看详细</el-button>
					</el-form-item>
					<el-form-item label="任期" v-if="ruleForm.status==1">
						<span class="xx"></span>
						<el-input disabled clearable v-model="ruleFormxj.tenure" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="上传报告图片" v-if="ruleForm.status==1">
						<ul class="imglist">
							<li v-for="(item,index) in fuList" :key="index">
								<el-image style="width: 100%; height: 100%" :preview-src-list="fuList" :src="item" />
							</li>
						</ul>
					</el-form-item>
					<el-form-item label="上传报告Word/PDF" v-if="ruleForm.status==1">
						<div class="fu-list">
							<div class="fu-li" v-for="(item,index) in fuannex" :key="index">
								{{item.OriginalName}} 
								<el-button @click="download(item.url)" color="#488bff"
									class="el-white btn" style="color: white" size="mini">下载
								</el-button>
							</div>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
		<!-- 工会三委委员 -->
		<member ref="member"></member>
		<!-- 经费审查委员会主任 -->
		<funds ref="funds"></funds>
		<!-- 女职工委员会主任 -->
		<workers ref="workers"></workers>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/word";
	import member from './member.vue'
	import funds from './funds.vue'
	import workers from './funds.vue'
	export default {
		components: {
			member,
			funds,
			workers
		},
		data() {
			return {
				outerVisible: false,
				ruleForm: {},
				srcList: [],
				annex: [],
				meet_time: "",
				president: "",
				vice_president: "",
				ruleFormxj: {},
				fuList: [],
				fuannex: []
			}
		},
		methods: {
			// 女职工委员会主任
			binworkers() {
				this.$refs.workers.show(this.ruleForm.id,this.ruleFormxj.trade_id)
			},
			// 经费审查委员会主任
			binfunds() {
				this.$refs.funds.show(this.ruleForm.id,this.ruleFormxj.trade_id)
			},
			// 工会三委委员
			binmember() {
				this.$refs.member.show(this.ruleForm.id,this.ruleFormxj.trade_id)
			},
			// 获取选举结果
			getElect(id) {
				this.$http.post(api.getElect, {
						trade_id: id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleFormxj = res.data
							if (res.data.pics) {
								this.fuList = res.data.pics.split(",")
							}
							if (res.data.annex) {
								this.fuannex = JSON.parse(res.data.annex)
							}
						}
					})
			},
			// 获取主席
			getzhu(id) {
				this.$http.post(api.getzhu, {
						trade_id: id
					})
					.then((res) => {
						if (res.code == 200) {
							this.president = res.data.president.name
							this.vice_president = res.data.vice_president.name
						}
					})
			},
			// 下载
			download(url) {
				window.location.href = url
			},
			// 获取信息
			getinfo(id) {
				this.$http.post(api.info, {
						id: id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							if (res.data.business_license) {
								this.srcList = res.data.business_license.split(",")
							}
							if (res.data.annex) {
								this.annex = JSON.parse(res.data.annex)
							}
							if (res.data.meet) {
								this.meet_time = res.data.meet.meet_time
							}
						}
					})
			},
			// 显示隐藏
			show(id) {
				this.outerVisible = !this.outerVisible
				this.getinfo(id)
				this.getzhu(id)
				this.getElect(id)
			}
		}
	}
</script>

<style scoped lang="scss">
	.title {
		font-size: 18px;
		color: #000;
		margin-bottom: 20px;
		display: flex;
		align-items: center;

		div {
			flex: 1
		}
	}

	.fu-li {
		width: 100%;
		font-size: 12px;
		height: 25px;
		margin-bottom: 10px;

		.btn {
			margin-left: 30px;
		}
	}

	.imglist {
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		li {
			width: 20%;
			height: 100px;
			padding-right: 10px;
		}
	}
</style>